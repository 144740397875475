body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
a.visited {
  color: #324DC7;
}

a {
  text-decoration: none;
}

/* .polymer-page {
  max-width: 800px;
  margin: 0 auto 0 auto;
  overflow: auto;
  height: auto;
  padding: 100px;
} */

:root {
  --content-builder-canvas-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /* --content-builder-ui-tint-color: orange; */
}

.rcp-root {
    --rcp-background-color: #fafafa;
    --rcp-field-label-color: #aaaaaa;  
    --rcp-field-input-color: black;
    --rcp-field-input-border-color: 0px;
}

.preview-grid {
  /* padding-left: 100px;
  padding-right: 100px; */
  box-sizing: border-box;
  padding: 70px;
  grid-template-columns: repeat(auto-fill, 180px);
  grid-gap: 40px;
  justify-items: center;
  justify-content: center;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 50px);
}

.with-fixed-header .with-top-toolbar .polymer-preview-item .page {
  margin-top: 0px;
}

.content-index-preview-item {
  box-shadow: 0px 12px 40px #00000020;
  border-radius: 16px;
  transition: 0.35s;
}

a:hover .content-index-preview-item {
  box-shadow: 0px 14px 40px #00000050;
  border-radius: 16px;
}

.content-index-preview-item-athena {
    border: 1px solid #00000020;
    padding-bottom: 5px;
    border-radius: 10px;
    box-shadow: 0px 12px 40px #00000020;
}

.content-index-preview-item-athena .page-renderer-inner-content {
    border-radius: 0px;
    background: unset;
}

a:hover .content-index-preview-item-athena {
    box-shadow: 0px 14px 40px #00000030;
    transition: 0.35s;
}
  
/* .preview-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-content: space-between;
  column-gap: 40px;
  row-gap: 40px;
} */

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-content p {
  color: var(--content-builder-text-value-color);
}

.login-input {
  width: 300px;
  height: 70px;
  gap: 12px;
}

.character {
  border: none;
  font-size: 28px;
  line-height: 65px;
  border-radius: 10px;

  /* light theme */
  color: #272729;
  background-color: #f6f5fa;
  border: 1px solid #00000020;
}

/* 
.character , .character--inactive {
  padding-left: 20px;
  padding-right: 20px;
} */


.info-panel {
    padding: 20px;
    margin: 20px;
    padding-bottom: 30px;
    width: 100%;
    font-size: 18px;
    line-height: 1.4;
    background-color: #00000080;
    -webkit-backdrop-filter: blur(30px);
    border-radius: 12px;
    font-weight: 600;   
    box-shadow: 0px 0px 30px #00000020; 
    text-align: left;
}

.info-panel-light {
    color: #666666;
    background-color: #ffffffcc;
}

.info-panel-light.solid {
    background-color: #ffffff;
    -webkit-backdrop-filter: none;
}

.info-panel-light h2 {
    color: black;
}

.info-panel-map h2 {
    font-weight: 600;    
    margin-top: 0px;
    padding-right: 60px;
}

.info-panel-map {
    padding: 0px;
    background: clear;
    color: white;
    pointer-events: none;
}

.map-info, .map-header {
    color: #ffffff;
    padding: 30px;
    font-weight: 600;
    font-size: 18px;
    padding-right: 50px;
    text-align: left !important;
}

.map-header {
    padding-bottom: 200px;
    padding-top: 50px;
    background: linear-gradient(#00000080, #00000000);
}
.map-header span {
    color: #ffffffdd;
    font-weight: 600;
    line-height: 1.5;
}

.map-header, .map-info {
    display: flex;
    flex-direction:column;
    gap: 10px;
}

.map-header h2 {
    margin: 0px;
    font-size: 44px;
    padding-right: 20px;
}

.map-info {
    padding-bottom: 50px;
    padding-top: 100px;
    background: linear-gradient(#00000000, #00000090);
}

.map-info span {
    color: #ffffffdd;
}
.map-info h2 {
    margin: 0px;
    font-size: 24px;
    padding-right: 32px;
}





/* 
.character , .character--inactive {
  padding-left: 20px;
  padding-right: 20px;
} */

.model-info-panel {
    padding: 20px;
    margin: 20px;
    padding-bottom: 30px;
    width: 100%;
    color: white;
    font-size: 18px;
    line-height: 1.4;
    background-color: #00000080;
    -webkit-backdrop-filter: blur(30px);
    border-radius: 18px;
    font-weight: 600;   
    box-shadow: 0px 0px 30px #00000020; 
}

.model-info-panel h2 {
    font-variation-settings: "wdth" 130;
    font-weight: 600;    
    color: var(--content-builder-canvas-tint-color);
    margin-top: 0px;
    padding-right: 60px;
}

.model-header-info {
    /* background: #35363B; */
    /* height: 500px;
    padding: 30px;
    padding-top: 80px;
    /* color: white; */
    /* text-align: left;  */
}

/* .model-header-info .brand {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
    padding-bottom: 4px;
}

.model-header-info h2 {
    font-variation-settings: "wdth" 130;
    font-weight: 800;    
    font-size: 44px;
    margin: 0px;
    padding-right: 40px;
}

.model-header-info h2.subtitle {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    color: transparent;
} */

.action-button {
    box-sizing: border-box;
    background-color: #0024A3;
}

/* .polymer-header-actions {
    gap: 24px;
} */

.resize-handle {
    border-right: 1px solid #00000020;
}

:root .monaco-editor  {
    --vscode-scrollbarSlider-background: #00000075;
}

.monaco-editor .slider {
    border-radius: 8px;  /* Rounded corners */
}

.layout-app .sidebar-tree {
    padding: 10px;
    padding-top: 12px;    
}

.inspector-right-content {
    position: relative;
}

.layout-app .layout-center-content {
    overflow : scroll;
    height: calc(100vh - 70px);
}

.layout-app .inspector-right-content {
    height: calc(100vh - 130px);
}

.layout-app .sidebar {
    height: calc(100vh - 65px)
}

.polymer-segmented-control {
    padding: 2px;
    border-radius: 8px;
}

/* .polymer-segmented-control-item {
    font-size: 15px;
} */
.polymer-segmented-control:hover {
    background-color: #eeeeee;
}
.polymer-segmented-control .polymer-segmented-control-item {
    color: #333333;
    padding: 6px;
}

.polymer-segmented-control .polymer-segmented-control-item.selected {
    bbox-shadow: unset;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
    bborder: 1px solid #e3e3e3;
}

.hide {
    display: none;
}

.file-header .polymer-header-actions {
    gap: 0px;
}
.file-header {
    z-index: 3 !important;
    position: relative;
}

.layout-center .polymer-header-regular-title .polymer-header-content {
    padding-left: 0px;
    padding-right: 0px;
    
    .polymer-header-actions-left {
        padding-left: 12px;
    }

    .polymer-header-actions {
        padding-right: 12px;
    }

    h1 {
        width: -webkit-fill-available !important;  
    }
}

/* Class for mobile devices (max width 480px) */
@media only screen and (max-width: 700px) {
    .screen-mobile {
        display: block !important; /* Show on mobile */
    }
}

/* Class for tablets (width 768px to 1024px) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .screen-tablet {
        display: block !important; /* Show on tablets */
    }
}

/* Class for laptops (width 1025px to 1440px) */
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .screen-laptop {
        display: block !important; /* Show on laptops */
    }
}


/* Class for large desktop screens (above 1440px) */
@media only screen and (min-width: 1441px) {
    .screen-desktop {
        display: block !important; /* Show on large desktops */
    }
}


/* Hide elements on mobile devices (max width 480px) */
@media only screen and (max-width: 700px) {
    .hide-mobile {
        display: none !important; /* Hide on mobile */
    }
}

/* Hide elements on tablets (width 768px to 1024px) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .hide-tablet {
        display: none !important; /* Hide on tablets */
    }
}


/* Hide elements on laptops (width 1025px to 1440px) */
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .hide-laptop {
        display: none !important; /* Hide on laptops */
    }
}

/* Hide elements on large desktop screens (above 1440px) */
@media only screen and (min-width: 1441px) {
    .hide-desktop {
        display: none !important; /* Hide on large desktops */
    }
}
