
/**
 Page
*/
.polymer-asset-catalogue-previews {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: #fafafa;
}

.polymer-page-content {
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
}

.polymer-page-content-fullscreen {
    /* max-width: var(--content-builder-content-width-fullscreen); */
}

.polymer-full-bleed {
    width: 100vw;
    margin-left: calc(50% - 50vw);
}

/**
 Content
*/
.polymer-content {
    padding: 25px;
}
.modal .polymer-content {
    padding: 50px;
}

.polymer-scroll-content {
    overflow-y: scroll;
    height: 100%;
    height: -webkit-fill-available;
    width: 100%;
}

/* TODO: Size based on if has header & toolbar */
.modal .polymer-scroll-content .polymer-content {
    padding-bottom: 85px;
    padding-top: 105px;
}

/** 
 * Modal Polymer Page 
 */
.modal .polymer-page {
    max-width: auto;
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
    overflow-y: clip;
}

.modal .polymer-page, .modal .polymer-page-content {
    max-width: auto;
    padding-top: 0px;
    width: 100%;
}

.modal .polymer-page  {
    margin: 0px;
}

.modal .polymer-page {
    max-width: intrinsic;
    max-width: -webkit-fill-available;
}

.modal .polymer-page-content {
    
}

.modal .polymer-page-content {
    width: 100%;
    padding: 0px;
}

/**
 Polymer Header
*/
.polymer-back-button {
    width: 24px;
    height: 24px;
    display: block;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjY3OTY4OCA1Ljc2OTUzQzAuNjgzNTk0IDUuNjIxMDkgMC43MTI4OTEgNS40ODYzMyAwLjc2NzU3OCA1LjM2NTIzQzAuODIyMjY2IDUuMjQwMjMgMC45MDgyMDMgNS4xMTkxNCAxLjAyNTM5IDUuMDAxOTVMNS40NTUwOCAwLjY2NjAxNkM1LjYzNDc3IDAuNDkwMjM0IDUuODUxNTYgMC40MDIzNDQgNi4xMDU0NyAwLjQwMjM0NEM2LjI3NzM0IDAuNDAyMzQ0IDYuNDMzNTkgMC40NDUzMTIgNi41NzQyMiAwLjUzMTI1QzYuNzE4NzUgMC42MTMyODEgNi44MzM5OCAwLjcyNDYwOSA2LjkxOTkyIDAuODY1MjM0QzcuMDA1ODYgMS4wMDU4NiA3LjA0ODgzIDEuMTYyMTEgNy4wNDg4MyAxLjMzMzk4QzcuMDQ4ODMgMS41OTU3IDYuOTQ3MjcgMS44MjQyMiA2Ljc0NDE0IDIuMDE5NTNMMi44ODI4MSA1Ljc2MzY3TDYuNzQ0MTQgOS41MTM2N0M2Ljk0NzI3IDkuNzE2OCA3LjA0ODgzIDkuOTQ3MjcgNy4wNDg4MyAxMC4yMDUxQzcuMDQ4ODMgMTAuMzc3IDcuMDA1ODYgMTAuNTMzMiA2LjkxOTkyIDEwLjY3MzhDNi44MzM5OCAxMC44MTQ1IDYuNzE4NzUgMTAuOTI1OCA2LjU3NDIyIDExLjAwNzhDNi40MzM1OSAxMS4wOTM4IDYuMjc3MzQgMTEuMTM2NyA2LjEwNTQ3IDExLjEzNjdDNS44NTE1NiAxMS4xMzY3IDUuNjM0NzcgMTEuMDQ2OSA1LjQ1NTA4IDEwLjg2NzJMMS4wMjUzOSA2LjUzMTI1QzAuOTA0Mjk3IDYuNDE0MDYgMC44MTY0MDYgNi4yOTQ5MiAwLjc2MTcxOSA2LjE3MzgzQzAuNzA3MDMxIDYuMDQ4ODMgMC42Nzk2ODggNS45MTQwNiAwLjY3OTY4OCA1Ljc2OTUzWiIgZmlsbD0iIzM4NENDMCIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-size: 24px 24px;
    cursor: pointer;
}

.polymer-header-regular-title .polymer-back-button {
    width: 17px;
    height: 22px;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMjg5MDYyIDEwLjI0NDFDMC4yOTYyMjQgMTAuMDIyMSAwLjMzOTE5MyA5LjgxODAzIDAuNDE3OTY5IDkuNjMxODRDMC41MDM5MDYgOS40NDU2NCAwLjYzMjgxMiA5LjI2NjYgMC44MDQ2ODggOS4wOTQ3M0w5LjA3NjE3IDEuMDA1ODZDOS4zNDgzMSAwLjczMzcyNCA5LjY4MTMyIDAuNTk3NjU2IDEwLjA3NTIgMC41OTc2NTZDMTAuMzQ3MyAwLjU5NzY1NiAxMC41OTA4IDAuNjYyMTA5IDEwLjgwNTcgMC43OTEwMTZDMTEuMDI3NyAwLjkxOTkyMiAxMS4yMDMxIDEuMDk1MzggMTEuMzMyIDEuMzE3MzhDMTEuNDY4MSAxLjUzMjIzIDExLjUzNjEgMS43NzIxNCAxMS41MzYxIDIuMDM3MTFDMTEuNTM2MSAyLjQzODE1IDExLjM4NTcgMi43ODkwNiAxMS4wODUgMy4wODk4NEwzLjcyNjU2IDEwLjI0NDFMMTEuMDg1IDE3LjM5ODRDMTEuMzg1NyAxNy43MDY0IDExLjUzNjEgMTguMDU3MyAxMS41MzYxIDE4LjQ1MTJDMTEuNTM2MSAxOC43MjMzIDExLjQ2ODEgMTguOTY2OCAxMS4zMzIgMTkuMTgxNkMxMS4yMDMxIDE5LjM5NjUgMTEuMDI3NyAxOS41Njg0IDEwLjgwNTcgMTkuNjk3M0MxMC41OTA4IDE5LjgyNjIgMTAuMzQ3MyAxOS44OTA2IDEwLjA3NTIgMTkuODkwNkM5LjY4MTMyIDE5Ljg5MDYgOS4zNDgzMSAxOS43NTQ2IDkuMDc2MTcgMTkuNDgyNEwwLjgwNDY4OCAxMS4zOTM2QzAuNjI1NjUxIDExLjIyMTcgMC40OTMxNjQgMTEuMDQyNiAwLjQwNzIyNyAxMC44NTY0QzAuMzI4NDUxIDEwLjY2MzEgMC4yODkwNjIgMTAuNDU5IDAuMjg5MDYyIDEwLjI0NDFaIiBmaWxsPSIjMzg0Q0MwIi8+Cjwvc3ZnPgo=');
    background-size: 17px 22px;
}

.polymer-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto 0 auto;
}

.polymer-header-regular-title.centered .polymer-header-content h1 {
    position: absolute;
    pointer-events: none;
    width: 100%;
    margin: 0 auto 0 auto;
    text-align: center;
    letter-spacing: initial;
}

.polymer-header-regular-title .subtitle {
    color: #989898;
    padding-left: 4px;
    transition: 0.25s;
}

.polymer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.polymer-header-actions, .polymer-header-actions-left {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
}

.polymer-header-actions-left-with-title {
    gap: 0px;
    align-items: center;
    justify-content: center;
}

.polymer-header-actions-left-with-title .polymer-header-button-with-content {
    margin-left: -24px;
}

.modal .polymer-header {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 10;
    background-color: rgba(255,255,255,0.7);
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
}

.polymer-header h2 {
    font-size: 16px;
    color: #888888;
    margin-top: 0px;
    margin-bottom: 16px;
}

.polymer-header-regular-title h1 {
    font-size: 16px;
}


.layout-center  .polymer-header-regular-title h1 {
    font-size: 14px;
    font-weight: 600;
}

.with-fixed-header .layout-center .polymer-header-regular-title {
    position: inherit;
    z-index: initial;
    width: initial;
    top: initial;
}


.polymer-header-regular-title {
    border-bottom: 1px solid #e3e3e3;
    height: var(--content-builder-header-height);
}

.polymer-page-content-fullscreen .polymer-header-regular-title {
    /* margin-left: calc(var(--content-builder-padding-fullscreen) / -2);
    margin-right: calc(var(--content-builder-padding-fullscreen) / -2); */

    background-color: rgba(255,255,255,0.8);
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
}

.polymer-page-content-fullscreen .polymer-header-regular-title.centered .polymer-header-content {
    width: 100%;
    /* padding-left: calc(var(--content-builder-padding-fullscreen) / 2);
    padding-right: calc(var(--content-builder-padding-fullscreen) / 2); */
}


.polymer-page-content-fullscreen .polymer-header-regular-title.centered .polymer-header-content h1 {
    width: calc(100vw - 100px);
}


.polymer-page-content-inset .polymer-header-regular-title.centered .polymer-header-content {
    width: 100%;
    /* padding-left: calc(var(--content-builder-padding) / 2);
    padding-right: calc(var(--content-builder-padding) / 2); */
}

.layout-center  .polymer-header-regular-title .polymer-header-content {
    padding-left: 24px;
    padding-right: 24px;    
}

.modal .polymer-page-content-inset .polymer-header-regular-title .polymer-header-content {
    padding-left: 16px;
    padding-right: 16px;
}

.polymer-page-content-inset .polymer-header-regular-title.centered .polymer-header-content h1 {
    width: 100%;
    left: 0px;
}

.polymer-header-large-title {
    padding-top: 50px;
}

/** Fixed Header Adjustments */

.with-fixed-header .polymer-header-regular-title {
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 0px;
}

.polymer-page.with-fixed-header {
    padding-top: var(--content-builder-header-height);
}


/** Buttons*/

.polymer-header-button {
    background: none;
    /* padding: 8px;
    padding-right: 15px;
    padding-left: 15px; */
    color: var(--content-builder-ui-tint-color);
    font-size: 15px;
    font-weight: 600;
    border: 0px;
    cursor: pointer;
    padding: 0px;
}

.polymer-header-button {
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;;
}

.polymer-header-button:hover {
    background: var(--content-builder-color-background);
}

.polymer-header-button , .polymer-header-button label, .polymer-header-button a {
    cursor: pointer;
}

.polymer-header-button.primary {
    background: var(--content-builder-ui-tint-color);
    padding: 8px;
    padding-right: 15px;
    padding-left: 15px;
    color: white;
    border-radius: 20px;
    border: 0px;
    font-size: 15px;
    font-weight: 600;
}

.polymer-header-button.highlight {
    background: #e3e3e3;
    padding: 8px;
    margin: -8px;
    border-radius: 8px;
}

.polymer-header-button.primary.disabled {
    background: #acacac;
}

.polymer-header-button-with-content {
    display: inline-flex;
    justify-content: center;   
    align-content: center;
}


.polymer-header-button-dropdown {
    position: absolute;
    padding-top: 0px;
    background-color: #00ff0020;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    min-width: 200px;
}

.polymer-header-button-dropdown-content {
    position: absolute;
    top: 40px;
    min-width: 200px;
    background-color: #ff000020;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 10px 30px #00000020;
    padding: 0px;
    cursor: pointer;
    pointer-events: initial;
}

.polymer-header-button-dropdown-content.right {
    right: -20px;
}

.polymer-header-button-dropdown.left .polymer-header-button-dropdown-content.left {
    min-width: unset;
}

.polymer-header-button-dropdown.left {
    width: 16px;
    min-width: unset;
    justify-content: flex-start;
}

/**
 Polymer Toolbar
*/
.polymer-toolbar {
    background-color: rgba(255,255,255,0.7);
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    width: -webkit-fill-available;
    position: absolute;
    z-index: 200;
}

.polymer-toolbar-bottom {
    bottom: 0px;
    border-top: 1px solid #e3e3e3;
}

.polymer-toolbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
}

/* .modal .polymer-toolbar-content {
    padding-right: 16px;
    padding-left: 16px;
} */

.polymer-toolbar-content span {
    font-weight: bold;
}

.polymer-toolbar-top {
    top: 0px;
    position: sticky;
    bborder-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}


.polymer-page.with-fixed-header .polymer-toolbar-top {
    position: fixed;
    top: calc(var(--content-builder-header-height) + 1px);
    /* margin-top: 15px; */
}

.polymer-page-content-fullscreen .polymer-toolbar-actions-center {
    position: absolute;
    width: calc(100vw);
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 0;
    left: 0px;
}

.polymer-page-content-fullscreen .polymer-toolbar-actions-right, .polymer-page-content-fullscreen .polymer-toolbar-actions-left {
    z-index: 1;
}

.polymer-toolbar-top .polymer-toolbar-content {
    /* max-width: var(--content-width); */
    margin-left: auto;
    margin-right: auto;
}

.polymer-page-content-fullscreen .polymer-toolbar-top .polymer-toolbar-content {
    /* max-width: var(--content-builder-content-width-fullscreen); */
}

.polymer-toolbar-actions {
    display: flex;
    gap: 16px;
}

/**
Grid
*/
.polymer-grid {
    display: grid;
}

.polymer-grid-1-columns {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
 }

 .polymer-grid-2-columns {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
 }
 
.polymer-grid-2-columns {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.polymer-grid-3-columns {
   grid-template-columns: repeat(3, 1fr);
   grid-gap: 20px;
}

.polymer-grid-4-columns {
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(4, 1fr);
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
}

.polymer-grid-gap-5 {
    grid-gap: 5px;
}
/**
Grid Albums
*/
.polymer-grid-albums-columns {
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr 1fr;
}

@media screen and (min-width: 400px) {
    .polymer-grid-albums-columns {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 800px) {
    .polymer-grid-albums-columns {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 1200px) {
    .polymer-grid-albums-columns {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 1900px) {
    .polymer-grid-albums-columns {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

/**
Grid Assets
*/
.polymer-grid-assets-columns {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: grid;
    grid-gap: 6px;
    grid-template-columns: 1fr 1fr;
}

.polymer-grid-assets-content-mode-fill {
    grid-gap: 6px;
}

.polymer-grid-assets-content-mode-fit {
    grid-gap: 16px;
}

@media screen and (min-width: 600px) {
    .polymer-grid-assets-columns {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 800px) {
    .polymer-grid-assets-columns {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 1000px) {
    .polymer-grid-assets-columns {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 1200px) {
    .polymer-grid-assets-columns {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 1900px) {
    .polymer-grid-assets-columns {
        max-width: initial;
        grid-template-columns: repeat(auto-fill, 150px);
    }
}


/**
Menu
*/
.polymer-menu.collapsed .polymer-menu-items {
    opacity: 0.0;
    pointer-events: none;
}

.polymer-menu.expanded .polymer-menu-items {
    opacity: 1.0;
    pointer-events: all;
}

.polymer-menu {
    cursor: pointer;
    transition: all 0.15s ease-out;
    position: relative;
}

.polymer-menu-items {
    transition: all 0.15s ease-out;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px #00000020;
    position: absolute;
    right: 0px;
    top: 25px;
    z-index: 1000;
    min-width: 150px;
}

.polymer-menu.top  .polymer-menu-items {
    bottom: 32px;
    top: unset;
}

.polymer-menu.left .polymer-menu-items{
    left: 0px;
}

.polymer-menu-item.destructive {
    color: #FF3D00;
}

.polymer-menu-action {
    display: flex;
    align-items: center;
    
}
.polymer-menu-item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    padding: 5px;
    padding-right: 10px;
    padding-left: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    color: var(--content-builder-text-color);
    /* color: var(--content-builder-ui-tint-color); */
    white-space: nowrap;
    margin: 4px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
}

.polymer-menu-header {
    padding-left: 15px;
    padding-bottom: 5px;
    padding-top: 10px;
    margin: 4px;
    font-size: 15px;
    display: block;
    text-align: left;
    font-weight: 600;
}

.polymer-menu-divider {
    border-top: 1px solid #eeeeee;
    margin-top: 4px;
    margin-bottom: 4px;
}

.polymer-menu-item:hover {
    background-color: var(--content-builder-color-background);
}

.polymer-menu-item .with-checkmark svg {
    margin-right: 6px;
    margin-left: -4px;
}

/**
Text Inptu
*/
.polymer-large-textfield {
    padding: 16px;
    background-color: #f3f3f3;
    border: 0px;
    border-radius: 8px;
    width: -webkit-fill-available;
    font-size: 16px;
}

/**
Slider
*/

.polymer-slider-track {
    height: 10px;
    background-color: var(--content-builder-ui-tint-color);
    border-radius: 4px;
}

.polymer-slider-track-1 {
    height: 10px;
    background-color: #e3e3e3;
    border-radius: 5px;
}

.polymer-slider-thumb {
    width: 24px;
    height: 24px;
    top: -8px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 4px 8px #00000040;
}

/**
Prev Next
*/
.polymer-prev-next {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}
.polymer-prev-next span {
    -webkit-user-select: none;
    -moz-user-select: none;
         user-select: none;
    pointer-events: none;
}
.polymer-prev-next svg {
    cursor: pointer;
}

/**
 Segmented Control
*/
.polymer-segmented-control {
    background-color: #f3f3f3;
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 5px;
    border-radius: 10px;
    font-size: 14px;
}

.polymer-segmented-control .polymer-segmented-control-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    color: black;
    border-radius: 6px;;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 500;
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
}

.polymer-segmented-control .polymer-segmented-control-item.selected {
    background-color: white;
    color: black;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
}

.polymer-segmented-control:hover {
    background-color: #eeeeee;
}

.dark .polymer-segmented-control {
    background-color: rgba(0,0,0,0.35);
}

.dark .polymer-segmented-control-item {
    color:rgba(255,255,255,0.9);
}

.dark .polymer-segmented-control-item.selected {
    background-color: white;
    color: black;
}




/**
 Notification
*/
.polymer-notification {
    position: fixed;
    top: -150px;
    z-index: 100;
    left: 0px;
    width: 100vw;
    display: flex;
    justify-content: center;
    transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.polymer-notification-visible {
    top: 80px;    
}

.polymer-notification-content {
    padding: 20px;
    background: white;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0px 10px 20px #00000020;
}

.preview-modal-inner-content {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
    gap: 20px;
    position: relative;
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
}

/* .preview-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
} */

/** write a media query when screen is less than 800px */
@media screen and (max-height: 800px) {

    .preview-modal-content {
        /* align-items: center;
        justify-content: flex-start; */
        /* pointer-events: inherit; */
        /* overflow-y: scroll; */
    }

    .preview-modal-inner-content {
        justify-content: flex-start;
        /* pointer-events: inherit; */
        overflow-y: scroll;
    }
    
    .preview-modal-content-header, .preview-modal-content .polymer-segmented-control {
        margin-top: 25px;
    }

    .polymer-preview-item {        
        padding-bottom: 50px;
    }
}
 

.preview-modal-inner-content > * {
    pointer-events: all;
}

/** Show shadow in modal */
.preview-modal-content .polymer-preview-item .page-content {
    box-shadow: 0px 10px 50px rgba(0,0,0,0.4);
}

.noscroll { 
    overflow: hidden;
}

.noscroll .polymer-page {
    overflow: hidden;
}

.preview-modal-content-header {
    color: white;
    font-size: 18px;
}

.preview-modal-close {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 10;
    width: 32px;
    height: 32px;
}


.asset-album-item img {
    border-radius: 10px;
}

/**
Assets
*/
.asset-catalogue a {
    text-decoration: none;
}

.asset-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}

.asset-item .loaded img, .asset-item .loaded canvas {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMnSURBVHgB7dYBEYAwEMCwBzHzb2czM/DRRESvz977DllrraHrHSBLACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACDsub8h65wzdDkACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACPsAGA4MhUaQUtgAAAAASUVORK5CYII=');
    background-size: 16px 16px;
    background-repeat: repeat;
}

.asset-item img, .asset-item canvas {
    border-radius: 4px;
}

.asset-item-empty {
    background: none;
}

/* .asset-image {
    width: 100%;
    height: 100%;
} */

.asset-image , .asset-image-content {
    max-width: 100%;
    max-height: 100%;
}

.asset-image-fit-width {
    width: 100%  !important;
    height: -moz-min-content  !important;
    height: min-content  !important;
}

.asset-image-fit-height {
    width: -moz-min-content  !important;
    width: min-content  !important;
    height: 100% !important;
}

.asset-item.asset-item-filled:hover {
    outline: 2px dashed #7a89cc;
    outline-offset: 0px;
}

.polymer-grid-assets-columns .asset-item {
    aspect-ratio: 1;
    /* width: min-content;
    height: min-content; */
}

.asset-item-uploader {
    border: 2px dashed #e3e3e3;
    background-color: white;
    border-radius: 6px;
}

.asset-item-uploader:hover {
    border: 2px dashed #7a89cc;
}

.asset-item-container {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.asset-item-container .action {
    color: var(--content-builder-ui-tint-color);
}

.asset-item-container button, .asset-file-chooser {
    border: 0px;
    background: none;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    outline: none;
    font-size: inherit;
    display: block;
}

.asset-item-details {
    color: var(--content-builder-text-value-color);
    padding: 20px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    font-size: 14px;    
}

.asset-item-details .asset-item-caption {
    font-size: 12px;
}
.asset-item-action {
    color: var(--content-builder-text-value-color);
    padding: 20px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
}

.asset-item .asset-item-details {
    color: black;
}

.asset-item.selected {
    position: relative;
}

.asset-item.selected::before {
    /* border-radius: 10px; */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid white;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 1;
}

.asset-item.selected::after {
    /* border-radius: 10px; */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--content-builder-highlight-color);
    box-sizing: border-box;
    pointer-events: none;
    z-index: 2;
}

.asset-item-details h2, .asset-item-details h6 {
    padding: 0px;
    margin: 0px;
}

.asset-item-container h2, .asset-item-details h6  {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    letter-spacing: normal;
}

.asset-item-uploader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.asset-item-container .error {
    color: var(--content-builder-color-red);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
}

.asset-item-container .error svg {
    margin-right: 5px;
    margin-top: -2px;
}

.asset-item.dragging {
    background-color: #ced7fd;
    border: 2px dashed var(--content-builder-ui-tint-color);
    color: var(--content-builder-ui-tint-color);
}

.asset-section {
    border-bottom: 1px solid silver;
    padding-top: 20px;
    padding-bottom: 20px;
}

.asset-section h1 {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
}

.asset-section-header {
    display: flex;
    justify-content: space-between;
}

.asset-detail-section .validation-indicator {
    color: var(--content-builder-text-value-color);
    font-size: 16px;
    letter-spacing: normal !important;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
}

.asset-detail-section-description {
    margin-top: -17px;
    max-width: 75%;
}

.asset-detail-section-description, .asset-detail-section-description p {
    line-height: 1.6;
    font-size: 14px;    
    color: var(--content-builder-text-description-color);
}

.asset-detail-section-description p {
    margin-top: 2px;
}

.asset-section-examples a {
    cursor: pointer;
    font-weight: bold;
}

.asset-section-header .info a {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 5px;
    font-weight: 600;
}

.asset-section p {
    font-size: 12px;
    color: var(--content-builder-text-description-color);
}

.asset-section-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

/**
 Asset Album
*/
.asset-album-item {
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    cursor: pointer;
}

.asset-album-item-placeholder {
    aspect-ratio: 1;
    background-color: #00000001;
    border: 1px dashed #00000040;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

.asset-album-item-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2px;
}

.asset-album-item div {
    border-radius: 10px;
}

.asset-album-title {
    font-weight: 700;
    font-size: 16px;
}

.asset-album-count {
    font-size: 16px;
    color: var(--content-builder-text-value-color);
}




/**
 * Asset Detail Screen
 */
 .asset-detail {
    /* border-top: 1px solid #e3e3e3; */
    height: -webkit-fill-available;
 }
 
 .asset-detail-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.asset-detail-image-with-previews {
    padding-top: 150px;
    padding-bottom: 50px;
}

.asset-detail-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.asset-detail-section-header.with-divider {
    border-top: 1px solid #e3e3e3;
}

.asset-detail-section-header .info a {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 5px;
    font-weight: 600;
    cursor: pointer;
}

.asset-detail-section-header h1, .asset-detail-section-header h5 {
    font-size: 16px;
    letter-spacing: normal !important;
    margin-top: 12px;
    margin-bottom: 12px;
}

.asset-detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    padding-top: 20px;
    padding-bottom: 20px;
}

.asset-detail-section-content-description {
    display: block;
    padding-bottom: 20px;
}

.asset-detail-row span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
}

.asset-detail-row a {
    text-decoration: none;
    cursor: pointer;
}

/* .asset-detail-row.first {
    border-top: 0px;
} */

.asset-detail-row.single {
    border-bottom: 1px solid #e3e3e3;
    border-top: 0px;
}

.asset-detail-row.destructive a, .asset-detail-row a.destructive {
    color: var(--content-builder-color-red) !important;
}

/* .asset-detail-content .asset-detail-row.last {
    padding-bottom: 0px;
} */



.asset-detail-row h2, .asset-detail-row h6 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
}

.asset-detail-row span {
    color: var(--content-builder-text-value-color);
}

/** Detail row of 'description' type */
.asset-detail-row-description {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.asset-detail-row-description-icon {
    margin-top: 2px;
    line-height: 13px;
}

.asset-detail-row-description-content {
    display: flex;
    gap: 15px;
    flex-direction: column;
    line-height: 1.25;
}

.asset-detail-row-description .asset-detail-row-description-content {
    flex-grow: 1;
}

.asset-detail-row-description-content span {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
}

.asset-detail-row-description-header {
    display: flex;
    justify-content: space-between;
}

.asset-detail-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.asset-detail-row-action {
    color: var(--content-builder-ui-tint-color);
}

.asset-detail-uploading {
    padding: 10px;
    width: 100%;
    max-width: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.asset-detail-uploading h2 {
    font-size: 16px;
}


.asset-preview-frame {
    width: 100%;
    height: 100%;
    border: 10px solid black;
    position: absolute;
    top: -10px;
    left: -10px;
    box-sizing: content-box;
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 5px #0000ff50;
    }

    50% {
        box-shadow: 0px 5px 20px #0000ff90;
    }

    100% {
        box-shadow: 0px 0px 5px #0000ff50;
    }
}

/* .asset-preview-layer-highlight-bottom {
    animation: pulse 1.85s ease-in-out infinite;
} */

.asset-preview-layer-highlight {
    animation: pulse 1.85s ease-in-out infinite;
}

/* .asset-preview-frame-outer {
  width: 100%;
  height: 100%;
  border: 1px solid #414141;
  position: absolute;
  top: -1px;
  left: -1px;
} */


.asset-detail-row-validation {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 5px;
}

.asset-detail-row-validation.warning {
    color: var(--content-builder-color-orange);
}

.asset-detail-row-validation.error {
    color: var(--content-builder-color-red);
}

.asset-item-details-warnings {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.asset-item-details-warnings .description {
    font-weight: 500;
    font-size: 16px;
    color: var(--content-builder-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.asset-item-details-warnings:hover .asset-item-details-warnings-detail, .asset-item-details-warnings:focus .asset-item-details-warnings-detail  {
    visibility: visible;
    opacity: 1;
    transition: all 0.1s ease-in;
    outline: none;
}

.asset-item-details-warnings-detail {
    visibility: hidden;
    opacity: 0;
    color: var( --content-builder-text-color);
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 50px;
    z-index: 1000;
    width: 400px;
    box-shadow: 0px 18px 40px #00000040;
    border-radius: 12px;
    font-weight: normal;
}

.asset-item-details-warnings-detail > .asset-detail-row.first {
    border-top: 0px;
}

.layout-right .asset-detail-row .polymer-slider {
    width: 200px;
}

.asset-detail-row .polymer-slider {
    width: 400px;
}

.asset-preview-pip {
    position: fixed;
    right: 50px;
    bottom: 50px;
    transition: all 0.2s ease-out;
}

.asset-preview-pip.show {
    opacity: 1.0
}

.asset-preview-pip.hide {
    opacity: 0.0
}

.asset-preview-pip .asset-preview-device , .asset-preview-device:hover {
    box-shadow: 0px 18px 40px #00000040;
}

.asset-preview-device {
    transition: box-shadow 0.2s ease-out;
    cursor: pointer;
    box-sizing: content-box;
}

.asset-preview-device > * {
    box-sizing: content-box;
}

/**
 Asset Preview Modal
*/
.asset-preview-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: -webkit-fill-available;    
    max-height: none;
} 

/** When asset preview modal is under a certain height let the content scroll, otherwise its centered */
@media (max-height: 900px) {
    .asset-preview-modal-content {
        max-height: 900px;
        overflow: scroll;
        align-items: flex-start;
    }    
    .asset-preview-modal-content .asset-preview-device {
        margin-top: 100px;
        margin-bottom: 150px;
    }
}

.asset-detail-section-header .polymer-header-button {
    padding-right: 0px;
}

/* .asset-previews {
  display: flex;
  flex-flow: row wrap;
  gap: 50px;
  row-gap: 50px;
  column-gap: 50px;
  justify-content: space-between;
  margin-top: 16px;
  margin-left: 8px;
} */

.polymer-asset-catalogue-previews .asset-previews {
    display: grid;
    grid-template-columns: 0.33fr 0.33fr 0.33fr;
    justify-content: space-between;
    align-content: space-between;
    -moz-column-gap: 66px;
         column-gap: 66px;
    row-gap: 80px;
    margin-top: 16px;
    margin-left: 8px;

}
.polymer-asset-catalogue-previews .asset-detail-section {
    padding-left: 20px;
    padding-right: 20px;
}



/**
Asset Catalogue Modals
*/
.asset-catalogue-modal {
    /* width: 80vw;
    height: 80vh; */
    /* padding: 50px;
    padding-top: 85px;
    padding-bottom: 0px; */
    overflow: auto;
    /* max-width: 800px; */
}

.asset-catalogue-example-modal .polymer-content {
    max-width: 800px;
    margin: 0 auto 0 auto;
}

.asset-catalogue-modal .polymer-page {
    padding-top: 0px;
}

.asset-catalogue-examples-previews h5 svg {
    position: relative;
    top: 1px;
}

.asset-catalogue-examples-previews h5 div {
    display: flex;
    align-items: first baseline;
    gap: 8px
}
.asset-catalogue-examples-previews h5 {
    line-height: 1.5;
}

.asset-catalogue-examples-previews .asset-previews {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
}

.asset-catalogue-examples-previews .asset-detail-section {
    padding-bottom: 100px;
    padding-top: 0px;
}
.asset-catalogue-examples-previews .asset-detail-section-content {
    padding-left: 8px;
    padding-top: 8px;
    padding-right: 8px;
}

/**
Asset Upload Dialog
*/
.asset-upload-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 50%;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto 0 auto;
    height: 100%;
}
.asset-upload-progress h1 {
    font-size: 16px;
    letter-spacing: normal;
}



@media only screen and (max-width: 1100px) {
    /* .modal .layout-right {
        flex-basis: auto;
        flex-grow: 1;
    }
    .modal .layout {
        height: auto;
        flex-direction: column;
    } */

}

:root {

    /* Content sizing */
    --content-builder-content-width: 880px;
    --content-builder-content-width-fullscreen: calc(100vw - 100px);
    --content-builder-content-min-canvas-height: 716px;
    --content-builder-content-canvas-width: 375px;

    --content-builder-padding-fullscreen: 100px;
    --content-builder-padding: 50px;
    --content-builder-padding-top: 50px;
    --content-builder-padding-bottom: 50px;

    /* Nav and toolbar sizing */
    --content-builder-top-toolbar-height: 80px;
    --content-builder-header-height: 64px;

    /* Color and fonts for the ui */
    --content-builder-ui-tint-color: #324DC7;
    --content-builder-ui-font: inherit;
    /* //-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
    
    /* Color and fonts for the content canvas */
    --content-builder-canvas-tint-color: #324DC7;
    --content-builder-canvas-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    /** Text */
    --content-builder-text-color: #333333;
    --content-builder-text-description-color: #333333;
    --content-builder-text-value-color: #767676;

    /* Highlight / Selection color */
    --content-builder-highlight-color: #0085FF;

    /* Base Colors */
    --content-builder-color-red: rgb(231, 55, 55);
    --content-builder-color-orange: #FF8A00;
    --content-builder-color-green: #45aa2b;
    --content-builder-color-icon-grey: #838383;
    --content-builder-color-background: #F6F6F6;
}
     
/** 
Semantic Colors
*/
.color-error, .color-destructive, .color-red {
    color: var(--content-builder-color-red);
    fill: var(--content-builder-color-red);
}

.color-warning, .color-orange {
    color: var(--content-builder-color-orange);
    fill: var(--content-builder-color-orange);
}

.color-correct {
    color: var(--content-builder-color-green);
    fill: var(--content-builder-color-green);
}

.color-tint {
    color: var(--content-builder-ui-tint-color);
    fill: var(--content-builder-ui-tint-color);
}

.color-validation-default, .color-valid, .color-value {
    color: var(--content-builder-color-icon-grey);
    fill: var(--content-builder-color-icon-grey);
}

.color-value {
    color: var(--content-builder-text-value-color);
    fill: var(--content-builder-text-value-color);
}

.empty-text {
    color: var(--content-builder-text-value-color);
}

.polymer {
    width: 100vw;
    position: relative;
    font-family: var(--content-builder-ui-font);
}

.polymer-page h1, .polymer h1, .polymer-page .polymer h1, .modal .polymer-page h1 {
    letter-spacing: normal;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.gradient-overlay {
    background-image: linear-gradient(0deg, #00000050, #00000000);
    width: 100%;
    height: 100%;
}

span {
    outline: 0px;
}

.polymer-page a , .polymer-page a:not([href]) {
    color: var(--content-builder-ui-tint-color);
}

/**
Screen Reader
*/
.yap-sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    clip-path: inset(50%) !important;  /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;            /* 3 */
}
/*
    Use in conjunction with .sr-only to only display content when it's focused.
    @note Useful for skip links 
    @see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
    @note Based on a HTML5 Boilerplate technique, included in Bootstrap
    @note Fixed a bug with position: static on iOS 10.0.2 + VoiceOver
        @author Sylvain Pigeard
        @see https://github.com/twbs/bootstrap/issues/20732
*/
.yap-sr-only-focusable:focus,
.yap-only-focusable:active {
    clip: auto !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
}

/**
Default Sizing Container
*/
.content-builder-container-fullscreen {
    max-width: calc(100vw - var(--content-builder-padding-fullscreen));
}

.content-builder-container-inset {
    max-width: 800px;
}

@media only screen and (max-width: 900px) {
    .content-builder-container-inset {
        max-width: calc(100vw - var(--content-builder-padding) * 2);
    }
}

.modal .content-builder-container-fullscreen {
    margin-left: 16px;
    margin-right: 16px;
    max-width: 100vw;
}

/**
  Main Layout
*/
.layout {
    height: 100%;
    height: -webkit-fill-available;
    display: flex;
    /* flex-wrap: wrap; */
}

.layout-top {
    flex-grow: 1000;
}

.layout-left {
    /* flex-basis: 19rem;
    flex-grow: 1; */
    height: 100%;
}

.layout-left .sidebar {
    
}

.layout-left .active, .inactive {
    transition: 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: absolute;
    left: 0px;
    z-index: 2;
    background-color: white;
}

.layout-left .active {
    left: 0px;
}

.layout-left .inactive {
    left: -340px;
}

.layout-right {
    flex-basis: 19rem;
    flex-grow: 1;
}

.layout-right .inspector-right {
    border-left: 1px solid #e3e3e3;
    flex-grow: 1;
    height: -webkit-fill-available;
}

.modal .layout-right {
    flex-basis: 24rem;
    flex-grow: 1;
    height: 100%;
    height: -webkit-fill-available;
}

.modal .layout-right {
    border-left: 1px solid #e3e3e3;
}

.layout-app .layout-right .inspector {
    height: calc(100vh - 0px);
    border-left: 1px solid #e3e3e3;
}

.layout-app .sidebar {
    height: calc(100vh - 0px - 50px);
    /* height: calc(100vh); */
}

.layout-app {
    height: auto;
}



.modal .layout-left {
    flex-basis: 15rem;
    flex-grow: 1;
    height: 100%;
}

.layout-center {
    flex-basis: 0;
    flex-grow: 999;
    position: relative;
    /* overflow: hidden; */
}


.modal .layout-center {
    height: -webkit-fill-available;
}

.layout-app > .layout-center {
    position: relative;
    overflow: initial;
}

.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.space-between-8 {
    gap: 8px;
    justify-content: space-between;
}

.space-between-4 {
    gap: 4px;
    justify-content: space-between;
}

.badge {
    background-color: #f3f3f3;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-content: center;
}

/**
Overlay
*/
.floating-overlay {
    pointer-events: none;
    position: absolute;
    width: 100vw;
    left: 0px;
    top: 0px;
    height: -webkit-fill-available;
    height: 100%;
    box-sizing: border-box;
    z-index: 100;
}

.floating-overlay-content {
    pointer-events: none;
    margin: 0 auto 0 auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    height: -webkit-fill-available;
    height: 100%;
}

.floating-overlay-inner-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    padding-top: var(--content-builder-padding-top);
    padding-bottom: var(--content-builder-padding-bottom);

    position: sticky;
    top: 0px;
    z-index: 100;
    height: -webkit-fit-content;   

}

.floating-overlay-left .floating-overlay-content {
    justify-content: flex-start;
} 

.floating-overlay .sidebar {
    position: sticky;
    padding-top: var(--content-builder-padding-top);
    top: 0px;
    z-index: 100;
    height: -webkit-fit-content;
    padding-bottom: var(--content-builder-padding-bottom);
}

.floating-overlay .sidebar-content {
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 10px 30px #00000020;
}

/* .floating-overlay .inspector {
    position: sticky;
    top: 0px;
    z-index: 100;
    height: -webkit-fit-content;   
} */

.floating-overlay-content > div, .floating-overlay-content > section {
    pointer-events: all;
    width: 320px;
}

.floating-overlay .inspector-content {
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 10px 30px #00000020;
}

.with-fixed-header .floating-overlay-inner-content, .with-fixed-header .floating-overlay .sidebar {
    top: var(--content-builder-header-height);
}

/* When toolbar is sticky */
.with-top-toolbar  .floating-overlay-inner-content, .with-top-toolbar  .sidebar {
    top: calc(var(--content-builder-top-toolbar-height));
}

.with-fixed-header .with-top-toolbar .floating-overlay-inner-content, .with-fixed-header .with-top-toolbar .sidebar  {
    top: calc(var(--content-builder-header-height) + var(--content-builder-top-toolbar-height));
}

.with-fixed-header .with-top-toolbar .page {
    margin-top: calc(var(--content-builder-top-toolbar-height) + var(--content-builder-padding-top));
}

/* Required if top toolbar isnt sticky */

/* .with-top-toolbar .floating-overlay {
    top: var(--content-builder-top-toolbar-height);
} */

/* .with-top-toolbar .page-container .page {
    padding-top: 80px;
}
.with-top-toolbar .layout-right .inspector {
    padding-top: 80px;
} */


/**
Canvas
*/
.canvas {
    background-color: var(--content-builder-color-background);
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;

}

.canvas-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.canvas-container {
    position: relative;
    width: 100%;
}

/**
Sidebar
*/
.sidebar {
    height: 100%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* .sidebar {
    overflow: auto;
} */

.sidebar-left {
    flex: 1;
    width: 340px;
    border-right: 1px solid #e3e3e3;
}

.sidebar-content {
    overflow-y: auto;
    height: -webkit-fill-available;
}

.sidebar h1 {
    padding: 0px;
    margin: 0px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    color: black;
}

.sidebar-toolbar {
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    min-height: 50px;
    align-items: center;
    width: -webkit-fill-available;
    padding-left: 20px;
    padding-right: 20px;

    -webkit-backdrop-filter: blur(20px);

            backdrop-filter: blur(20px);
    background-color: rgba(255,255,255,0.65);
}

.sidebar-toolbar-safearea {
    padding-bottom: 50px;
}

.bottom .sidebar-toolbar {
    position: fixed;
    left: 0px;
    bottom: 0px;
}

.sidebar-toolbar-action {

}

.sidebar-toolbar-action-content {
    color: var(--content-builder-ui-tint-color);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 12px;
    gap: 4px;
}

.sidebar .subtitle {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: rgba(0, 0, 0, 0.3);
    color: rgb(0, 153, 255);
}

.sidebar .desc {
    font-size: 12px;
    font-weight: bold;
    margin-top: 3px;
    color: rgba(0, 0, 0, 0.3);
}

.sidebar-separator {
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar-section-content p {
    line-height: 1.5;
    color: #838383;
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.sidebar-section .sidebar-section-content p {
    color: #838383;
    font-size: 16px;
    font-weight: 500;
}

.sidebar-section.hover .sidebar-section-content p {
    color: #333333;
}

.sidebar h2,
.toolbar h2 {
    font-size: 14px;
}


.blueprint h2 {
    font-size: 16px !important;
}


.sidebar-tree {
    font-size: 15px;
    padding: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
}
.modal .sidebar-tree {
    padding-top: 15px;
}

.sidebar-tree.top {
    padding-top: 5px;
}

.sidebar-item {
    cursor: pointer;
    min-height: 26px;
}

.sidebar-item .selected,  .sidebar-item-content.selected:hover  {
    background-color: var(--content-builder-ui-tint-color);
    color: white;
}

.sidebar-item-content:hover {
    background-color: var(--content-builder-color-background);
}

.sidebar-item-action .sidebar-item-content {
    color: var(--content-builder-ui-tint-color);
}

.sidebar-item-content {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
}

.sidebar-item span {}

.sidebar-item-content > .icon {
    width: 32px;
    margin-top: 4px;
}

.sidebar-item .disclosure {
    width: 22px;
    height: 18px;
    top: 5px;
    display: inline-block;
}

.sidebar .inner-content {
    width: -webkit-fill-available;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sidebar-item-children {
    display: block;
}

.sidebar-tile {
    margin-bottom: 20px;
}

.sidebar-tile-content {
    height: 150px;
    background-color: #F6F6F6;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
}

.sidebar-tile.selected .sidebar-tile-content {
    border: 2px solid #0085FF;
}

.sidebar-tile-page {
    width: 40px;
    height: calc(40px * 2.16);
    background-color: black;
    border-radius: 4px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
    background-size: cover;
}

.sidebar-tile-title {
    padding: 5px;
    text-align: center;
    font-size: 14px;
}

.nav-back {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: 0px;
    color: #0085FF;
    cursor: pointer;
}

/**

*/
.sidebar-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
}

.sidebar-list-alt {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
}

/** 
Page
**/

.page {
    margin-top: 50px;
    padding-bottom: 1px;
}

.page h1 {
    font-size: 14px;
    font-weight: normal;
    color: #00000055;
    text-align: left;
}

.page-content {
    width: var(--content-builder-content-canvas-width);
    min-height: var(--content-builder-content-min-canvas-height);
    background-color: white;
    border-radius: 15px;
    box-shadow: #00000022 0px 20px 40px;
    margin-bottom: 130px;
    font-family: var(--content-builder-canvas-font);
}

.page-content {
    display: flex;
    flex-direction: column;
}


.page.selected .page-content {
    borderr: 3px solid #0085FF;
}


/**
View
*/
.view {
    cursor: pointer;
    display: contents;
    /* display: flex; */
    /* display: contents;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    width: 100%; */
}

.view.highlighted  > *:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -3px;
    pointer-events: none;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border: 1px solid var(--content-builder-highlight-color);
    border-radius: 4px;
    width: 100%;
}

.view > * {
    position: relative;
    /* z-index: 1; */
    overflow: visible !important;
}

.view.selected > *:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -4px;
    pointer-events: none;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 2px solid var(--content-builder-highlight-color);
    border-radius: 4px;
    width: 100%;
}

img.view-image {
    width: 100%;
}

.view-text {
    display: block;
    overflow-wrap: anywhere;
}

/**
Section
*/
.polymer-page-section {
    display: contents;
}

.section {
    position: relative;
}

.section-title {
    position: absolute;
    right: -220px;
    top: 10px;
    width: 200px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 600;
    cursor: pointer;
}

.highlighted .section-title {
    color: rgba(0, 0, 0, 0.8);
}

.section-add {
    min-height: 200px;
    height: 100%;
    flex-grow: 1;
    background-color: rgb(240, 240, 240);
    display: flex;
    color: rgba(0, 0, 0, 0.25);
    justify-content: center;
    border: 4px solid rgb(240, 240, 240, 0.0);
    align-items: center;
    position: relative;
}

.section-add-dragging-over {
    border: 4px dashed rgb(0, 153, 255);
    background-color: rgb(0, 153, 255, 0.1);
}

/* .section {
  border: 1px solid #00000000;
}

.section.highlighted {
  border-top: 1px dashed #0085FF99;
  border-bottom: 1px dashed #0085FF99;
} */


.section-hover {
    position: absolute;
    pointer-events: none;
    z-index: 1;
}

.section-hover-content {
    position: relative;
    height: 100%;
}

.section-hover-outline {
    position: absolute;
    pointer-events: none;
    left: -1000px;
    right: -1000px;
    top: 0px;
    bottom: 0px;
    border-top: 0.5px dashed #888888;
    border-bottom: 0.5px dashed #888888;
}

.section-insert {
    pointer-events: all;
    display: flex;
    justify-content: flex-end;
    justify-content: center;
    height: 20px;
    width: 100%;
    position: absolute;
    z-index: 200;
}

.section-insert svg {
    pointer-events: all;
    /* box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    background: black; */
}
.section-insert-top {
    top: -10px;
    /* right: -12px; */
}

.section-insert-bottom {
    bottom: -10px;
    /* right: -12px; */
}

/* .section-hover-outside-right {
    position: absolute;
    top: -100px;
    bottom: -100px;
    nbackground-color: blue;
    position: absolute;
    width: 200px;
    right: -300px;
    pointer-events: initial;
}

.section-hover-outside-right-bottom {
    position: absolute;
    bottom: -200px;
    nbackground-color: blue;
    position: absolute;
    width: 100px;
    height: 200px;
    right: -100px;
    pointer-events: initial;
} */


.section-hover-outside-right-top {
    position: absolute;
    top: -200px;
    nbackground-color: blue;
    position: absolute;
    width: 100px;
    height: 200px;
    right: -100px;
    pointer-events: initial;
}

.section-hover-outside-left {
    position: absolute;
    top: -100px;
    bottom: -100px;
    width: 50px;
    left: -50px;
    nbackground-color: blue;
    position: absolute;
    pointer-events: initial;
}


/* 
.section.highlighted:after {
  content: " ";
  position: absolute;
  z-index: 100;
  top: 0px;
  pointer-events: none;
  left: -1000px;
  right: -1000px;
  bottom: 0px;
  border-top: 1px dashed #00000055;
  border-bottom: 1px dashed red;
} */


.section-controls {
    height: 100%;
    min-height: 200px;
    width: 100px;
    top: -15px;
    right: -120px;
    padding-left: 20px;
    padding-top: 0px;
    position: absolute;
    pointer-events: initial;
    z-index: 100;
}

.section-controls-content {
    hheight: 125px;
    width: 40px;
    margin-top: 10px;
    margin-left: 0px;
    background-color: white;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.section-controls-content .control {
    margin-bottom: 0px;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
}

.section-controls-content .control.disabled {
    opacity: 0.25;
    pointer-events: none;
}

.section-controls-content .control:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* .section.highlighted .section-controls-content {
  display: block;
} */

/**
Stack
*/
.stack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
}

.hstack {
    align-items: center;
}

.stack-element {
    display: block;
}

.fill-available-width {
    width: -webkit-fill-available;
}

/**
ZStack
*/
.zstack {
    display: flex;
    position: relative;
    align-items: center;
}

.zstack-element {
    /* height: 812px; */
    /* height: 100%; */
    position: absolute;
    z-index: 1;
    pointer-events: none;
    width: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: row;
}

.zstack-element>div {
    width: 100%;
}

.zstack-element .view, .zstack-element .allow-interaction {
    pointer-events: initial;
}

/**
Elements
*/
.view {}

.view-text {
    width: 100%;
}

.view-seperator {
    border: 0.5px solid rgba(0, 0, 0, 0.1);
}

/**
Inspector
*/
.inspector {
    /* height: 100vh; */
    /* width: 250px; */
    width: 100%;
    /* position: fixed;
  right: 25px;
  top: 75px; */
    /* background-color: white; */
    /* box-shadow: 0px 17px 34px rgba(0, 0, 0, 0.1); */
    /* border-radius: 13px; */
    font-size: 10pt;
}

.inspector span {
    line-height: 1.75;
}

.inspector section {
    padding-bottom: 20px;
}

.inspector section:not(:first-child) {
    padding-top: 15px;
    border-top: 1px solid #00000020;
}

.inspector section:last-child {
    padding-bottom: 0px;
}

.inspector-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.inspector h1 {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    color: black;
}

.inspector-header-with-icon, .inspector-property-title-with-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.inspector-header-with-divider {
    border-top: 1px solid #eeeeee;
    margin-top: 20px !important;
    padding-top: 15px !important;
}

.inspector h2 {
    padding: 0px;
    margin: 0px;
    font-size: 10pt;
    font-weight: normal;
    color: black;
}

.inspector-property {
    cursor: default;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 20px;
    padding-top: 15px;
    padding-bottom: 0px;
    margin-bottom: -2px;
}

.inspector-property h2 {
    width: 50%;
    float: left;
}

.inspector-property-value {
    float: right;
    width: 50%;
    font-size: 10pt;
    text-align: right;
    color: rgba(0, 0, 0, 0.5);
}

.inspector-property-description {
    font-size: 10pt;
    color: rgba(0, 0, 0, 0.5);
}

.inspector-location {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.08);
}

.inspector-image-well {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.08);
    cursor: pointer;
    position: relative;
    border: 1px solid rgba(0,0,0, 0.01);
    aspect-ratio: auto;
}

.inspector-image-well-condensed {
    border-radius: 8px;
    width: -webkit-fill-available;
    padding-left: 10px;
    min-height: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    cursor: pointer;
    border: 1px solid rgba(0,0,0, 0.08);
    background-color: white;
    position: relative;
}

.inspector-image-well-condensed .remove {
    position: absolute;
    float: right;
    right: 12px;
    padding-top: 3px;
    cursor: pointer;
}

.inspector-image-well-condensed .inspector-image-well-title span {
    padding: 0px;
    padding: 0px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    text-align: left;
    width: 140px;
    white-space: nowrap;
}

.inspector-list-item .inspector-image-well-condensed .inspector-image-well-title span {
    width: 170px;   
}

.inspector-image-well-condensed .thumbnail {
    width: 24px;
    height: 24px;
    background: #e3e3e3;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0, 0.01);
    clip: true;
}

.inspector-image-well-condensed .thumbnail img, .inspector-image-well-condensed .thumbnail div {
    border-radius: 8px;
    clip: auto;
}

.inspector-image-well-condensed {
    border-radius: 8px;
    width: -webkit-fill-available;
    padding-left: 10px;
    min-height: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    cursor: pointer;
    border: 1px solid rgba(0,0,0, 0.08);
    background-color: white;
}

.inspector-image-well-condensed .inspector-image-well-title span {
    padding: 0px;
    padding: 0px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    text-align: left;
    width: 140px;
    white-space: nowrap;
}
.inspector-image-well-condensed .thumbnail {
    width: 24px;
    height: 24px;
    background: #e3e3e3;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0, 0.01);
    clip: true;
}

.inspector-image-well-condensed .thumbnail img, .inspector-image-well-condensed .thumbnail div {
    border-radius: 8px;
    clip: auto;
}


.inspector-image-well img, .inspector-image-well div, .inspector-image-well canvas {
    border-radius: 8px;
    clip: auto;
}

.inspector-image-well.image-filled {
    align-items: center;
    /* background-color: unset; */
    height: initial;
    border: 0px;
}

.inspector-image-well-action {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: calc(100% - 4px);
    background-color: rgba(0,0,0,0.2);
    transition: 0.2s;
}

.inspector-image-well-action:hover {
    opacity: 1.0;
}

.inspector-image-well-action span {
    display: block;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    background: rgba(0,0,0,0.5);
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
}

.inspector-image-well .inspector-image-well-title {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.inspector-image-well .inspector-image-well-title span {
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    padding: 10px;
    text-align: center;
}

.inspector-video-well, .inspector-file-well {
    cursor: pointer;
    border: 1px solid rgba(0,0,0, 0.08);
    color: var(--content-builder-ui-tint-color);
    border-radius: 8px;
    width: 100%;
}

.inspector-video-well-title, .inspector-file-well-title {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 35px; */
    text-align: left;
}

.inspector-video-well-title.video-filled, .inspector-file-well-title.file-filled {
    color: var(--content-builder-text-value-color);
}

.inspector-video-well span, .inspector-file-well span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 10px;
}

.inspector-video-well span.accessory, .inspector-file-well span.accessory {
    text-align: right;
    color: var(--content-builder-ui-tint-color);
}

.inspector-property-requirement, .inspector-header-requirement {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 8px;
    padding-top: 10px;
    line-height: 1.5;
}

.inspector-property-requirement {
    padding-bottomm: 10px;
    padding-top: 10px;
}

.inspector-property-requirement-icon {
    padding-top: 1px;
    line-height: 12px;
}

.inspector-property-validations .inspector-requirement-item {
    border-top: 0px;
    padding-top: 5px;
}

.inspector-property-with-divider {
    border-top: 1px solid #eeeeee;
    margin-top: 20px;
}

.inspector-property-single-column .inspector-property {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.inspector-property-single-column .inspector-property-value, .inspector-property-single-column .control-input {
    width: -webkit-fill-available;
    text-align: left;
}

.polymer-grid .control-input {
    text-align: left;
    width: 100%;
}

.inspector-property-single-column textarea {
    min-height: 100px;
}

.inspector-property-single-column .inspector-property-validations {
    padding-top: 5px;
}

.inspector-button {
    width: 100%;
    border: 0px;
    padding: 14px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 10pt;
    cursor: pointer;
    color: var(--content-builder-ui-tint-color);
}

.inspector-list-item {
    display: flex;
    justify-content: space-between;
    gap: 6px;
}

.inspector-list-item button {
    border: 0px;
    background-color: initial;
    cursor: pointer;
}

.inspector-list-item-highlight {
    background-color: #f5f5f5;
    margin-top: -6px;
    margin-bottom: -6px;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
}

/* .inspector-property-validations {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 20px;
} */

.control-select {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M%200%209%20L%204.5%204.5%20L%200%200%22%20transform%3D%22translate(5.75%203.75)%20rotate(90%202.25%204.5)%22%20fill%3D%22transparent%22%20stroke-width%3D%222%22%20stroke%3D%22rgb(153%2C%20153%2C%20153)%22%20stroke-linecap%3D%22round%22%3E%3C%2Fpath%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E");
    padding: 0px 18px 0px 0px;
    font-size: 10pt;
    font-weight: 500;
    height: 20px;
    color: rgba(0, 0, 0, 0.5);
    pointer-events: auto;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    position: relative;
    border: none;
    margin: 0px;
    cursor: pointer;
    outline: 0px;
    display: inline-block;
    caret-color: rgb(68, 68, 68);
    box-shadow: rgb(0, 153, 255) 0px 0px 0px 0px inset;
    background-position: right 0px center;
    background-repeat: no-repeat no-repeat;
    text-align: right;
}

.control-input {
    margin: 0px;
    font-size: 10pt;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    outline: 0px;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    text-align: right;
    margin-right: 4px;
    width: 120px;
}

.control-segmented {
    display: flex;
    justify-content: flex-end;
}

.control-segmented-option {
    display: flex;
    align-items: center;
    width: 25px;
    height: 25px;
    background-position: center center;
    background-repeat: no-repeat no-repeat;
    border-radius: 4px;
}

.control-segmented-option.selected {
    background-color: #e3e3e3;
}

input[type="range"].control-slider {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;    
    background: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: #e3e3e3;
    height: 0.5rem;
    border-radius: 0.25rem;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -4px; /* Centers thumb on the track */
    background-color: #ffffff;
    height: 1rem;
    width: 1rem;    
    border-radius: 0.5rem;
    box-shadow: 0px 2px 5px #00000020;
    border: 0.5px solid #00000040;
 }


.option-icon {
    width: 25px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat no-repeat;
}

.align-left {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.18579 1.76709H13.9211C14.2083 1.76709 14.4475 1.5415 14.4475 1.24756C14.4475 0.960449 14.2083 0.734863 13.9211 0.734863H1.18579C0.898682 0.734863 0.673096 0.960449 0.673096 1.24756C0.673096 1.5415 0.898682 1.76709 1.18579 1.76709ZM1.18579 5.34912H8.87622C9.16333 5.34912 9.39575 5.12354 9.39575 4.82959C9.39575 4.54248 9.16333 4.31689 8.87622 4.31689H1.18579C0.898682 4.31689 0.673096 4.54248 0.673096 4.82959C0.673096 5.12354 0.898682 5.34912 1.18579 5.34912ZM1.18579 8.93115H13.9211C14.2083 8.93115 14.4475 8.69873 14.4475 8.41162C14.4475 8.12451 14.2083 7.89893 13.9211 7.89893H1.18579C0.898682 7.89893 0.673096 8.12451 0.673096 8.41162C0.673096 8.69873 0.898682 8.93115 1.18579 8.93115ZM1.18579 12.5063H8.87622C9.16333 12.5063 9.39575 12.2808 9.39575 11.9937C9.39575 11.7065 9.16333 11.4741 8.87622 11.4741H1.18579C0.898682 11.4741 0.673096 11.7065 0.673096 11.9937C0.673096 12.2808 0.898682 12.5063 1.18579 12.5063Z' fill='%23313030'/%3E%3C/svg%3E%0A");
}

.align-right {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.606934 1.76709H13.3423C13.6294 1.76709 13.8687 1.5415 13.8687 1.24756C13.8687 0.960449 13.6294 0.734863 13.3423 0.734863H0.606934C0.319824 0.734863 0.0942383 0.960449 0.0942383 1.24756C0.0942383 1.5415 0.319824 1.76709 0.606934 1.76709ZM5.65869 5.34912H13.3423C13.6294 5.34912 13.8687 5.12354 13.8687 4.82959C13.8687 4.54248 13.6294 4.31689 13.3423 4.31689H5.65869C5.36475 4.31689 5.13916 4.54248 5.13916 4.82959C5.13916 5.12354 5.36475 5.34912 5.65869 5.34912ZM0.606934 8.93115H13.3423C13.6294 8.93115 13.8687 8.69873 13.8687 8.41162C13.8687 8.12451 13.6294 7.89893 13.3423 7.89893H0.606934C0.319824 7.89893 0.0942383 8.12451 0.0942383 8.41162C0.0942383 8.69873 0.319824 8.93115 0.606934 8.93115ZM5.65869 12.5063H13.3423C13.6294 12.5063 13.8687 12.2808 13.8687 11.9937C13.8687 11.7065 13.6294 11.4741 13.3423 11.4741H5.65869C5.36475 11.4741 5.13916 11.7065 5.13916 11.9937C5.13916 12.2808 5.36475 12.5063 5.65869 12.5063Z' fill='%23313030'/%3E%3C/svg%3E%0A");
}

.align-center {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.573975 1.76709H13.3093C13.5964 1.76709 13.8357 1.5415 13.8357 1.24756C13.8357 0.960449 13.5964 0.734863 13.3093 0.734863H0.573975C0.286865 0.734863 0.0612793 0.960449 0.0612793 1.24756C0.0612793 1.5415 0.286865 1.76709 0.573975 1.76709ZM3.10327 5.34912H10.7869C11.074 5.34912 11.3064 5.12354 11.3064 4.82959C11.3064 4.54248 11.074 4.31689 10.7869 4.31689H3.10327C2.80933 4.31689 2.58374 4.54248 2.58374 4.82959C2.58374 5.12354 2.80933 5.34912 3.10327 5.34912ZM0.573975 8.93115H13.3093C13.5964 8.93115 13.8357 8.69873 13.8357 8.41162C13.8357 8.12451 13.5964 7.89893 13.3093 7.89893H0.573975C0.286865 7.89893 0.0612793 8.12451 0.0612793 8.41162C0.0612793 8.69873 0.286865 8.93115 0.573975 8.93115ZM3.10327 12.5063H10.7869C11.074 12.5063 11.3064 12.2808 11.3064 11.9937C11.3064 11.7065 11.074 11.4741 10.7869 11.4741H3.10327C2.80933 11.4741 2.58374 11.7065 2.58374 11.9937C2.58374 12.2808 2.80933 12.5063 3.10327 12.5063Z' fill='%23313030'/%3E%3C/svg%3E%0A");
}


/**
Modal
*/
.modal {
    position: absolute;
    width: 800px;
    height: 500px;
    border-radius: 16px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 0px;
    outline: 0px;
    overflow: hidden;
    clip-path: stroke-box;
}

.modal-fullscreen {
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
}

.modal-overlay {
    z-index: 2000;
    position: fixed;
    outline: 0px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    padding: 25px;
    padding-top: 15px;
    height: calc(100% - 35px);
}

.modal-content h2 {
    font-size: 17px;
}


.section-template-items {
    overflow-y: scroll;
    height: -webkit-fill-available;
    padding-bottom: 30px;
}

.section-template-item {
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.section-template-item {
    border: 1px solid #e3e3e3;
    padding: 10px;
    border-radius: 10px;
}

.template-item {
    padding: 0px;
}

.template-item svg {
    border-radius: 10px;
}

.section-template-item img {
    transition: opacity;
    width: 100%;
    
}

.section-template-item-component {
    pointer-events: all;;
    background-color: #f2f2f2;
}

.section-template-item-component span {
    font-size: 10pt;
    font-weight: 500;
    padding-top: 6px;
    display: block;
    padding-bottom: 2px;
}

.section-template-item-component img, .section-template-item-component svg {
    border-radius: 10px;
}

.section-template-item-icon img {
    border-radius: 10px;
    transition: opacity;
    max-width: 75px;
}


.section-template-item:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}

.section-template-grid {
    /* Not needed if autoprefixing */
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -20px;
    /* gutter size offset */
    width: auto;
}

.section-template-grid-item {
    padding-left: 20px;
    /* gutter size */
    background-clip: padding-box;
    display: block;
}


/**
Header
*/
.title-header-container {
    margin-top: 8px;
}

.header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
}

.header-container-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.header-container {
    cursor: pointer;
}

.header-container .count {
    color: rgba(0, 0, 0, 0.2);
    font-weight: 600;
}

.header-container .action {
    /* color:  rgb(0, 153, 255); */
    color: var(--content-builder-ui-tint-color);

    cursor: pointer;
    font-size: 14px;
}

.header-container .action.action-disabled {
    color: rgba(150, 150, 150);
}

/** 
Toolbar
*/
.toolbar {
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.toolbar-left,
.toolbar-center,
.toolbar-right {
    width: 33%;
}

.toolbar-left {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
}

.toolbar-center {
    text-align: center;
}

.toolbar-right {
    justify-content: flex-end;
    display: flex;
}

.toolbar h1 {
    font-size: 14px;
}

.toolbar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    ;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
}

.toolbar-text {
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;
    color: rgb(179, 179, 179);
    cursor: pointer;
}

.toolbar-text.selected {
    color: black;
}

.toolbar-icon:hover,
.toolbar-icon.selected {
    background-color: #eeeeee;
}

.toolbar-icon.selected:hover {
    background-color: #cfcfcf;
}

/***
Blueprintws
**/
.blueprint-thumbs {
    display: grid;
    -moz-column-gap: 20px;
         column-gap: 20px;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.blueprint-thumbs img,
.blueprint-thumbs .thumb {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 5px 30px #00000020;
    background-size: cover;
}

/***
Templates
**/
.template-category-item {
    cursor: pointer;
    position: sticky;
    top: 0;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: -25px;
    margin-right: -25px;
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.8);
}

.template-category-item .content {
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) 50%;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDkgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuNCIgZD0iTTguMTEzNzcgNi43MTE5MUM4LjExMzc3IDYuNDExNjIgOC4wMDM5MSA2LjE2MjYgNy43Njk1MyA1LjkyODIyTDIuMTI5ODggMC40MTMwODZDMS45Mzk0NSAwLjIyMjY1NiAxLjcxMjQgMC4xMzQ3NjYgMS40NDE0MSAwLjEzNDc2NkMwLjg5MjA5IDAuMTM0NzY2IDAuNDQ1MzEyIDAuNTY2ODk1IDAuNDQ1MzEyIDEuMTE2MjFDMC40NDUzMTIgMS4zODcyMSAwLjU2MjUgMS42MzYyMyAwLjc2MDI1NCAxLjgzMzk4TDUuNzcwMDIgNi43MDQ1OUwwLjc2MDI1NCAxMS41ODk4QzAuNTYyNSAxMS43ODc2IDAuNDQ1MzEyIDEyLjAyOTMgMC40NDUzMTIgMTIuMzA3NkMwLjQ0NTMxMiAxMi44NTY5IDAuODkyMDkgMTMuMjk2NCAxLjQ0MTQxIDEzLjI5NjRDMS43MTI0IDEzLjI5NjQgMS45Mzk0NSAxMy4yMDEyIDIuMTI5ODggMTMuMDEwN0w3Ljc2OTUzIDcuNDk1NjFDOC4wMTEyMyA3LjI2MTIzIDguMTEzNzcgNy4wMTIyMSA4LjExMzc3IDYuNzExOTFaIiBmaWxsPSIjNDM0MzQzIi8+Cjwvc3ZnPgo=');
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: -8px;
    margin-right: -16px;
    border-radius: 8px;
    gap: 8px;
}

.template-category-item .content:hover {
    background-color: #f7f7f7;
}

.template-category-item.expanded .content {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE1IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuNCIgZD0iTTcuNTQ3ODUgNy44MTM0OEM3Ljg0ODE0IDcuODEzNDggOC4xMDQ0OSA3LjY5NjI5IDguMzM4ODcgNy40NTQ1OUwxMy44NDY3IDEuODIyMjdDMTQuMDM3MSAxLjYzMTg0IDE0LjEzMjMgMS40MDQ3OSAxNC4xMzIzIDEuMTMzNzlDMTQuMTMyMyAwLjU4NDQ3MyAxMy42OTI5IDAuMTM3Njk1IDEzLjE0MzYgMC4xMzc2OTVDMTIuODc5OSAwLjEzNzY5NSAxMi42MjM1IDAuMjU0ODgzIDEyLjQyNTggMC40NTI2MzdMNy41NTUxOCA1LjQ2MjRMMi42Njk5MiAwLjQ1MjYzN0MyLjQ3MjE3IDAuMjU0ODgzIDIuMjIzMTQgMC4xMzc2OTUgMS45NDQ4MiAwLjEzNzY5NUMxLjQwMjgzIDAuMTM3Njk1IDAuOTYzMzc5IDAuNTg0NDczIDAuOTYzMzc5IDEuMTMzNzlDMC45NjMzNzkgMS40MDQ3OSAxLjA2NTkyIDEuNjMxODQgMS4yNDkwMiAxLjgyMjI3TDYuNzU2ODQgNy40NjE5MUM2Ljk5ODU0IDcuNjk2MjkgNy4yNDc1NiA3LjgxMzQ4IDcuNTQ3ODUgNy44MTM0OFoiIGZpbGw9IiM0MzQzNDMiLz4KPC9zdmc+Cg==');
}

.template-category-item .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 8px;
}

.template-category-item img {
    width: 48px;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
}

.template-category-item .description {
    padding-right: 50px;
    line-height: 1.5;
    padding-top: 3px;
    font-size: 14px;
}

.template-category-item .title {
    font-size: 14px;
    font-weight: bold;
}

/**
Box
*/
.box {}


/**
*/
.video-link {
    cursor: pointer;
    height: 70px;
    border-radius: 10px;
    ;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
    padding: 5px;
    padding-left: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.video-link:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.video-link img {
    width: 52px;
    height: 52px;
}

.video-link .content {
    display: flex;
    flex-grow: 1;
    ;
    flex-direction: column;
}

.video-link h1 {
    font-size: 13px;
}

.video-link span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}

.modal.modal-video {
    height: 800px;
    width: 370px;

    background-color: unset;
    box-shadow: none;
}

.video-container {
    width: 100%;
    height: 100%;
}

.video-container video {
    width: 100%;
    height: 100%;
}


/**
Title
*/
.group-title {
    grid-column-end: span 3;
    grid-row-end: span 4;
}


/**
Button
*/
.button.large {
    width: 100%;
    padding: 10px;
    background-color: #F5F5F5;
    color: var(--content-builder-ui-tint-color);
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

.button.large.selected {
    background-color:var(--content-builder-ui-tint-color);
    color: white;
}

.action-button {
    width: -webkit-fill-available;
    padding: 10px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: var(--content-builder-canvas-tint-color);
    font-weight: 600;
    color: #ffffff;
    border-radius: 10px;
    text-align: center;
}



/**
Polymer Button
*/
.polymer-button {
    background: none;
    padding-top: 8px;
    padding-bottom: 8px;
    color: var(--content-builder-ui-tint-color);
    font-size: 16px;
    font-weight: 600;
    border: 0px;
}

/**
 Polymer Progress Bar
*/
.polymer-progress-bar {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: #D9D9D9;
}

.polymer-progress-bar-inner {
    height: 10px;
    border-radius: 5px;
    background-color: var(--content-builder-ui-tint-color);
}


/**
 Upload modal
*/
.asset-uploading-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
}

.asset-uploading-title h1 {
    font-size: 16px;
}

.asset-uploading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.asset-uploading-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: center;
    width: 50%;
    margin: 0 auto 0 auto;
}

/**
Preview
*/
.polymer-preview-item {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    cursor: default;
    transform-origin: top left;
}

.polymer-preview-item-content {
    transform-origin: top left;
}

.polymer-preview-item .page-content {
    border: 20px solid black;
    border-radius: 40px;
    max-height: 816px;
    box-shadow: none;
    margin: 0px;
    overflow: auto;
    box-sizing: content-box;
    font-family: var(--content-font);
}


.polymer-preview-item.interaction-disabled > * {
    pointer-events: none;
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    -moz-user-select: none;
         user-select: none; /* Standard syntax */
    cursor: default;
}

.polymer-preview-item.interaction-disabled {
    pointer-events: none;
}

.polymer-preview-item.interaction-disabled .page-content {
    overflow: clip;
}

.polymer-preview-item .page {
    margin-top: 0px;
}

/** 
Validatiobn
*/
.polymer-validation-items-content {
    display: flex;
    padding: 20px;
    flex-direction: column;
    position: absolute;
    gap: 20px;
    top: 0px;
    left: 0px;
}


.polymer-validation-item {
    display: flex;
    flex-direction: column;
    width: 250px;
    box-sizing: border-box;
    padding: 20px;
    gap: 20px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 10px 20px #00000020;
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
         user-select: none;
}

.polymer-validation-item-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 8px;
}

.polymer-validation-item-icon {
    padding-top: 2px;
}

.polymer-validation-item-content {
    display: flex;
    flex-direction: column;
    gap: 1px;
    line-height: 1.4;
    flex-grow: 1;
}

.polymer-validation-item h1 {
    margin: 0px;
    font-size: 14px;
}

.polymer-validation-item h2 {
    font-size: 11px;
    margin: 0px;
    text-transform: uppercase;
}

.polymer-validation-item-error {
    border: 3px solid white;
}

.polymer-validation-item-error h1 {
    color: var(--content-builder-color-red);
}

.polymer-validation-item-error.selected, .polymer-validation-section-highlight-error {
    border: 3px solid var(--content-builder-color-red);
}

.polymer-validation-item-warning {
    border: 3px solid white;
}

.polymer-validation-item-warning h1 {
    color: var(--content-builder-color-orange);
}

.polymer-validation-item-warning.selected, .polymer-validation-section-highlight-warning {
    border: 3px solid var(--content-builder-color-orange);
}

.polymer-validation-item span {
    margin: 0px;
    font-size: 14px;
    font-weight: normal;
}

.polymer-validation-indicator {
    width: 12px;
    height: 12px;
    padding: 12px;
    cursor: pointer;
    line-height: 12px;
}

.polymer-validation-indicator-content {
    /** Increase hover area */
    padding: 8px;
    margin: -8px;
}

.polymer-back-button-empty {
    visibility: hidden;;
}

/** React Calendar **/
.react-calendar {
    width: auto;
    max-width: 100%;
    background: white;
    border: 1px solid #e3e3e3;
    font-family: inherit;
    line-height: 1.125em;
    padding: 12px;
    background: white;
    border-radius: 8px;
}

.react-calendar__tile:hover {
    background: #e3e3e3;
    border-radius: 4px;
}

.react-calendar__tile--now:enabled, 
.react-calendar__tile--now:enabled:focus {
    color: var(--content-builder-ui-tint-color);
    background: white;
    border-radius: 4px;
}

.react-calendar__tile:hover,
.react-calendar__tile--now:enabled:hover, 
.react-calendar__tile--now:hover 
{
    background: #e3e3e3;
    border-radius: 4px;
}

.react-calendar__tile--active:enabled {
    background: var(--content-builder-ui-tint-color);
    color: white;
    border-radius: 4px;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:hover .react-calendar__tile--active:enabled:focus {
    background: var(--content-builder-ui-tint-color);
    color: white;
    border-radius: 4px;
}

.react-calendar__month-view__days__day--weekend {
    color: #8c8c8c;
}

.ReactCrop__drag-handle {
    background-color: white;
    border-radius: 3px;
    border: 1.5px solid var(--content-builder-ui-tint-color);
}

.asset-detail-image-crop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.asset-detail-image-crop-info {
    position: absolute;
    height: 0px;
    bottom: -20px;
    color: var(--content-builder-text-value-color);
    font-size: 10pt;

    position: absolute;
    /* height: 0px; */
    bottom: -30px;
    /* color: white; */
    /* color: var(--content-builder-text-value-color); */
    font-size: 10pt;
    /* background-color: #6c6c6c; */
    padding: 8px;
    border-radius: 8px;
    font-feature-settings: monospaced;
    font-variant-numeric: tabular-nums;
    font-weight: 600;

}
